export interface Logo {
  altText?: string;
  asset?: Asset;
}

export enum InventoryStatus {
  InStock = 'IN_STOCK',
  ShipToStore = 'SHIP_TO_STORE',
  OutOfStock = 'OUT_OF_STOCK',
  NotFound = 'NOT_FOUND',
}

export enum ReserveStatus {
  InventoryBased = 'reserve-inventory-based',
  ChatWithMobilist = 'reserve-chat-with-mobilist', // DEFAULT
  Always = 'reserve-always',
}

export enum ItemType {
  Phone = 'Phone',
  Accessory = 'Accessory',
}

export enum InventoryItemType {
  phone = 'phone',
  accessory = 'accessory',
}

export enum DeviceType {
  Phone = 'phone',
  Tablet = 'tablet',
  PrepaidPhone = 'prepaid phone',
}

export enum Contentful {
  ContentfulId = 'contentful_id',
  TypeName = '__typename',
}

export enum ContentType {
  ContentfulTextSection = 'ContentfulTextSection',
  ContentfulCombinatorSection = 'ContentfulCombinatorSection',
  ContentfulCardItem = 'ContentfulCardItem',
  ContentfulFaqItem = 'ContentfulFaqItem',
  ContentfulBlade = 'ContentfulBlade',
  ContentfulImageSection = 'ContentfulImageSection',
  ContentfulBanner = 'ContentfulBanner',
  ContentfulPlan = 'ContentfulPlan',
  ContentfulMobilistTip = 'ContentfulMobilistTip',
  ContentfulPhonesSection = 'ContentfulPhonesSection',
  ContentfulCta = 'ContentfulCta',
}

export enum ContentHideOption {
  KIOSK = 'KIOSK',
  TMS = 'TMS',
}

export interface KioskProps {
  isKiosk: boolean;
}

export interface GatsbyPageContextProps {
  locale: string;
  urlSlug: string;
}

export enum RecaptchaSize {
  NORMAL = 'normal',
  INVISIBLE = 'invisible',
  COMPACT = 'compact',
}

interface ProductInventoryResponseItem {
  storeId?: string;
  productSku?: string;
  status: InventoryStatus;
  productType: InventoryItemType;
}

export interface ProductInventoryResponse {
  response: ProductInventoryResponseItem[];
}

export interface DppSkuResponseItem {
  productSku?: string;
  price?: number;
  monthlyFee: number;
  replacementFee: number;
}

export interface DppSkuResponse {
  response: DppSkuResponseItem[];
}

export interface ResumatorResponseItem {
  id?: string;
  teamId: string;
  title: string;
  countryId: string;
  city: string;
  state: string;
  zip: string;
  department: string;
  minimumSalary: string;
  maximumSalary: string;
  notes: string;
  originalOpenDate: string;
  type?: string;
  status?: string;
  sendToJobBoards?: string;
  hiringLead: string;
  boardCode: string;
  internalCode: string;
  questionnaire: string;
}

interface DeviceProtectionPlanTiersResponseItem {
  monthlyFee: number;
  replacementFee: number;
  tierMax: number;
  tierMin: number;
}

export interface DeviceProtectionPlanTiersResponse {
  tiers: DeviceProtectionPlanTiersResponseItem[];
}

export interface StoreLocationResponse {
  storeId: string;
  address: string;
  postalCode: string;
  city: string;
  province: string;
}

export interface NearbyStore {
  commonInfo: CommonInfo;
  categoryBullseyeIds: string[];
  childLocationIds: string;
  categoryIds: string[];
  storeBanner: StoreBanner;
}

export interface CommonInfo {
  lang: string;
  locationName: string;
  lclId: string;
  beId: number;
  tel: string;
  fax: string;
  distance: number;
  address: Address;
  contactInfo: ContactInfo;
  businessHours: BusinessHour[];
  bannerLogoUrl: string;
}

export interface Address {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
}
export interface BusinessHour {
  closed?: boolean;
  openingTime?: string;
  closingTime?: string;
  nameOfDay?: string;
  holidayHours?: boolean;
  holidayName?: string;
}

export interface ContactInfo {
  name?: string;
  position?: string;
  tel?: string;
  fax?: string;
  cell?: string;
  eMail?: string;
  url?: string;
}

export interface StoreBanner {
  name?: string;
  id?: string;
}

export interface StoreLogo {
  storeName: string;
  storeId?: string;
  logo?: Logo;
  asset?: Asset;
  altText?: string;
}

export interface Asset {
  url?: string;
  localImage?: LocalImage;
}

export interface LocalImage {
  id?: string;
  publicURL?: string;
  childImageSharp?: ChildImageSharp;
}

export interface ChildImageSharp {
  gatsbyImageData?: GatsbyImageData;
}

export interface GatsbyImageData {
  layout?: string;
  backgroundColor?: string;
  images?: Images;
  width?: number;
  height?: number;
}

export interface Images {
  fallback?: Fallback;
  sources?: Source[];
}

export interface Fallback {
  src?: string;
  srcSet?: string;
  sizes?: string;
}

export interface Source {
  srcSet?: string;
  type?: string;
  sizes?: string;
}

export interface CartItemLabels {
  upfrontCostLabel: string;
  carrierLabel: string;
  monthlyCostLabel: string;
  pcoPointsLabel: string;
  redeemableForLabel: string;
  valueInOurStoresLabel: string;
  tradeInEstimateLabel: string;
  deviceProtectionPlanAddedLabel: string;
  removeLabel: string;
  accessoryLabel: string;
  phoneLabel: string;
  prepaidPhoneLabel: string;
  tabletLabel: string;
  itemsCountLabel: string;
  itemCountLabel: string;
}

export interface DocumentI {
  raw: string;
}

import { IntlProvider } from 'react-intl';
import React, { ReactNode } from 'react';
import en from '../locales/en.json';
import fr from '../locales/fr.json';
import CookieScript from '../scripts/CookieScript';

const withIntlProvider = (locale: string) => (children: ReactNode) => (
  <IntlProvider
    locale={locale || 'en'}
    defaultLocale="en"
    messages={locale === 'fr' ? fr : en}
  >
    {children}
  </IntlProvider>
);
interface PageContext {
  locale: string;
}
interface Props {
  pageContext: PageContext;
}
export default ({ element, props }: { element: ReactNode; props: Props }) => {
  if (!props) {
    return null;
  }

  const { pageContext } = props;
  const { locale } = pageContext;

  return withIntlProvider(locale)(
    <>
      {element}
      <CookieScript />
    </>,
  );
};

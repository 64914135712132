import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showKioskSetup: false,
};

export const sharedSlice = createSlice({
  name: 'Shared',
  initialState,
  reducers: {
    updateShowKioskSetup: (state, action) => {
      state.showKioskSetup = action.payload;
    },
  },
});

export const { updateShowKioskSetup } = sharedSlice.actions;

export default sharedSlice.reducer;

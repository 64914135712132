import { upperFirst, orderBy } from 'lodash';

export const createAccessoryFilterOptions = (
  filter: string,
  data: Record<string, any>[],
  phones: Record<string, any>[] = [],
) => {
  if (filter === 'phone') {
    return allFilters(filter, phones);
  }
  if (filter === 'sort') {
    return allSorters(filter, data);
  }
  if (filter !== 'phone' && filter !== 'sort') {
    return allFilters(filter, data);
  }
  return [];
};

// Function to create labels for Filters
const allFilters = (id: string, items: Record<string, any>[]) => {
  switch (id) {
    case 'type':
      return orderBy(
        items
          .filter(({ accessory_category }) => accessory_category)
          .map(({ accessory_category }) => accessory_category)
          .flat()
          .map(({ categoryName }) => ({
            name: upperFirst(categoryName.trim()),
            enabled: false,
          }))
          .filter(
            ({ name }, index, array) => array.findIndex((item) => item.name.trim() === name.trim())
              === index,
          ),
        ['name'],
        ['asc'],
      );

    case 'colour':
      return orderBy(
        items
          .filter(({ variants }) => variants)
          .map(({ variants }) => variants)
          .flat()
          .filter((item) => item.accessoryColour)
          .map(({ accessoryColour }) => ({
            name: upperFirst(accessoryColour.displayName.trim()),
            hexValue: accessoryColour.hexValue,
            assetUrl: accessoryColour.assetUrl,
            enabled: false,
          }))
          .filter(
            ({ name }, index, array) => array.findIndex(
              (colour) => colour.name.trim() === name.trim(),
            ) === index,
          ),
        ['name'],
        ['asc'],
      );

    case 'brand':
      return orderBy(
        items
          .filter(({ manufacturerName }) => manufacturerName)
          .map(({ manufacturerName }) => ({
            name: upperFirst(manufacturerName.trim()),
            enabled: false,
          }))

          .filter(
            ({ name }, index, array) => array.findIndex((item) => item.name.trim() === name.trim())
              === index,
          ),
        ['name'],
        ['asc'],
      );

    case 'phone':
      return items
        .map(({ contentful_id }) => ({
          name: contentful_id,
          enabled: false,
        }))
        .filter(
          ({ name }, index, array) => array.findIndex((item) => item.name.trim() === name.trim())
            === index,
        );

    default:
      return [];
  }
};

// Function to create labels for sorters
const allSorters = (id: string, sortLabels: Record<string, any>[]) => {
  switch (id) {
    case 'sort':
      return sortLabels.map((label) => ({
        name: label.value,
        enabled: false,
        key: label.key,
      }));

    default:
      return [];
  }
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 720px)","md":"(max-width: 1023px)","lg":"(max-width: 1536px)","desktop":"(min-width: 1024px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"fr"}],"fields":[{"name":"type","store":true},{"name":"question","store":true},{"name":"answer","store":true}],"resolvers":{"ContentfulFaqItem":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Mobile Shop","short_name":"TMS","start_url":"/","background_color":"#ffffff","theme_color":"#eb0000","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f51d49d5711c178bf4c29734ae9dfca2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P86B5P2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-pcs-ada-chatbot/gatsby-browser.js'),
      options: {"plugins":[],"handle":"pcmobileshop","delay":0,"zIndex":1000,"dismissButton":true,"dismissDuration":"session","adaSettings":{}},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-akamai-bot-manager/gatsby-browser.js'),
      options: {"plugins":[],"whitelist":["themobileshop.ca"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
